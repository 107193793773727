import React, { useEffect } from "react"
import { Link } from "@reach/router"

import Layout from "../components/layout"
import Seo from "../components/seo"

const OrderSuccessPage = (location) => {

  useEffect(() => {
    const init = async () => {
      const search = location.location.search
      const orderId = search.replace('?order=', '')
      const orderRes = await fetch(`/api/m2/order/${orderId}`)
      const order = await orderRes.json()
      const ecommercePayload = {
        ecommerce: {
          currency: order.base_currency_code,
          value: order.base_subtotal_incl_tax,
          tax: order.base_tax_amount,
          shipping: order.shipping_amount,
          transaction_id: order.increment_id,
          coupon: order.coupon_code || '',
          items: order.items.map((item) => {
            return {
              item_id: item.sku,
              item_name: item.name,
              item_category: '',
              item_brand: 'FarmaMed',
              quantity: item.qty_ordered,
              price: item.base_row_total_incl_tax,
            }
          }),

        },
      }
      if (window) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'purchase',
          ...ecommercePayload
        })

      }
    }
    // init()
  }, [])

  return (
    <Layout name="p-error">
      <Seo title="Ordine confermato" />
      <div className="a-container">
        <div className="p-error-content">
          <h2>Ordine confermato</h2>
          <h4>Il tuo ordine è stato ricevuto e verrà a breve preso in carico.</h4><br />
          Per vedere i tuoi ordini <a href="https://shop.farmamed.it/it/sales/order/history/">clicca qui</a><br /><br /><br />
          <Link to="/">
            Clicca qui per tornare alla home page
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default OrderSuccessPage
